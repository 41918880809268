import React from 'react'
import { Link } from 'react-scroll';
import { KasetFairLogo } from './KasetFairLogo.jsx'

export default function NavBar() {
  const menuItems = ['หน้าแรก', 'เกี่ยวกับงาน', 'ผู้พัฒนา']
  const menuHref = ['mainsection', 'about', 'credit']

  return (
    <nav className='flex justify-start bg-white bg-opacity-80 space-x-4 ml-8 text-lg'>
        <KasetFairLogo/>
        {menuItems.map((item, index) => (
          <div className='my-auto' key={item}>
            <Link 
              to={menuHref[index]} 
              spy={true} 
              smooth={true} 
              duration={500} 
              className="text-xl hover:text-[#006C67] hover:font-semibold transition"
              >
              {item}
            </Link>

          </div>
        ))}

    </nav>
  )
}
