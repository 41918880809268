import React from "react";
import TimeSlot from "../Component/MainSection";
import Footer from "../Component/Footer";
import NavBar from "../Component/NavBar";
import '@fontsource-variable/noto-sans-thai'
import AboutSection from "../Component/AboutSection";
import Credit from "../Component/Credit";

export default function TimeMachine(){
  return(
    <main title="Home - Kasetfair Time Machine">
      <header id="header-content" class="fixed w-full top-0">
        <NavBar/>
      </header>
      <article className="mt-20 space-y-8">
        <div id="mainsection" className="flex justify-center">
          <span className="text-3xl text-center font-bold bg-gradient-to-r from-[#006C67] via-[#069A8E] to-[#B2BB1E] text-transparent bg-clip-text bg-300% animate-gradient uppercase">Kasetfair <span class="whitespace-nowrap">Time Maching</span></span>
        </div>
        <div>
        <TimeSlot/>
        </div>
        <div className="flex justify-center ">
          <div>
          <p className="text-lg text-center">เลื่อนหน้าจอเพื่อเลือกชมเพิ่มเติม</p>
          <p className="text-lg text-center">คลิก "เข้าชม" เพื่อเข้าชมเกษตรแฟร์ประจำปีต่างๆ</p>
          </div>
        </div>
      </article>
      <AboutSection/>
      <Credit/>
      <Footer/>
    </main>
  ); 
}
