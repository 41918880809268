export default function Credit(){
    return (
        <div id="credit" className="space-y-4 mb-8">
            <div className="flex justify-center">
                <span className="text-4xl font-bold bg-gradient-to-r from-[#006C67] via-[#069A8E] to-[#B2BB1E] text-transparent bg-clip-text bg-300% animate-gradient">พัฒนาโดย</span>
            </div>
            <div className="flex justify-center mx-auto ">
                <div className="space-y-4">
                    <a href="https://github.com/freelancetheoser" className="flex justify-center space-x-2 cursor-pointer">
                        <img src="https://github.com/freelancetheoser.png?size=40" alt="" className="rounded-full h-10"/>
                        <span className="my-auto cursor-pointer">ทินรัตน์ จีระกุลกิจ</span>
                    </a>
                    <a href="https://cs.sci.ku.ac.th/" className="flex justify-center space-x-2 cursor-pointer">
                        <span className="my-auto cursor-pointer">ภาควิชา วิทยาการคอมพิวเตอร์ คณะวิทยาศาสตร์</span>
                    </a>
                </div>
            </div>
        </div>
    );
}