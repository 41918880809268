import './App.css';
import TimeMachine from './Page/TimeMachineHub';

function App() {
  return (
    <div title="Kasetfair Time Machine">
      <TimeMachine/>
    </div>
  );
}

export default App;
