
export default function AboutSection(){

  const TimeMachinePoster = "./TimeSlot/2567.jpg"

  return (
    <section id="about">
      <div className="flex items-center justify-center">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-12 md:gap-24 max-w-screen-lg my-16 mx-14">
          <div class="col-span-1">
            <h1 class="text-3xl font-bold my-6 ">
              <span className="text-3xl font-bold bg-gradient-to-r from-[#006C67] via-[#069A8E] to-[#B2BB1E] text-transparent bg-clip-text bg-300% animate-gradient uppercase"> Kasetfair Time Machine ?</span>
            </h1>
            <p class="text-[20px] my-5 text-gray-700">
            “Kasetfair time machine” ฐานข้อมูลย้อนเวลาสำหรับอนาคต ที่มหาวิทยาลัยเกษตรศาสตร์เก็บไว้ให้ 
            เป็นฐานข้อมูลร้านค้าเกษตรแฟร์ปีต่าง ๆ ที่สามารถเดินชมได้แบบเสมือนจริง 360 องศา 
            มีร้านค้าครบครัน ไม่ต่ำกว่า 1,500 ร้าน ในแต่ละปี “Kasetfair time machine” จึงเป็นแหล่งรวมร้านค้าและสินค้าไว้มากมาย ที่สำคัญ เป็นร้านค้า sme ขนาดเล็ก แม่ค้ารายย่อย และเกษตรกรทั่วไป ที่เข้าถึงช่องทางการตลาดได้ยาก ดังนั้น sme ขนาดเล็ก แม่ค้ารายย่อย และเกษตรกรที่เคยมาขายสินค้าในงานเกษตรแฟร์แต่ละปี จะมี Kasetfair time machine เป็นช่องทางที่ให้ผู้บริโภคสามารถย้อนเวลากลับไปค้นหา เข้าถึงสินค้าและผลผลิตทางการเกษตร ได้โดยตรง และไม่ต้องผ่านพ่อค้าคนกลาง มีความยั่งยืน
            </p>
          </div>
          <div class="flex col-span-1 relative">
            <div class="m-auto max-h-[480px] rotate-3">
              <img src={TimeMachinePoster} alt="KasetFair Poster" />
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center">
        <div class="grid grid-cols-1 gap-12 md:gap-24 max-w-screen-lg mx-14">
          <div class="flex col-span-1 relative">
            <p class="text-[20px] my-5 text-gray-700">
            นอกจากนี้ “Kasetfair time machine” ยังเป็นฐานข้อมูลนวัตกรรมและงานวิจัยให้ผู้ประกอบการ นักลงทุน ธุรกิจ startup ที่มองหานวัตกรรมใหม่ ๆ ไป<span class="whitespace-nowrap">ต่อยอด</span>ธุรกิจหรือผลิตภัณฑ์ของตัวเอง ได้ย้อนเวลากลับไปค้นหา เดินชม นวัตกรรมและผลงานวิจัยด้านการเกษตร วิทยาศาสตร์และเทคโนโลยี ของมหาวิทยาลัยเกษตรศาสตร์ ที่นำมาจัดแสดงในงานเกษตรแฟร์ทุก ๆ ปี ซึ่งแต่ละปี จะมีนวัตกรรมใหม่ ๆ ผลงานวิจัยใหม่ ๆ มาจัดแสดงแบบไม่ซ้ำเดิม เป็นการถ่ายทอดความรู้และเทคโนโลยี ให้แก่สังคมอย่างต่อเนื่องไม่สิ้นสุด ผู้สนใจสามารถติดต่อนักวิจัยหรือสถาบันเพื่อต่อยอดเพิ่มเติมได้จากรายละเอียดที่ติดไว้ให้ใน time machine
            </p>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-12 md:gap-24 max-w-screen-lg my-16 mx-14">
          <div class="col-span-1">
            <h1 class="text-3xl font-bold my-6 ">
              <span className="text-3xl font-bold bg-gradient-to-r from-[#006C67] via-[#069A8E] to-[#B2BB1E] text-transparent bg-clip-text bg-300% animate-gradient uppercase">ข้อมูลเพิ่มเติม</span>
            </h1>
            <p class="text-[20px] my-5 text-gray-700">นอกจากนี้ “Kasetfair time machine” ยังเป็นฐานข้อมูลนวัตกรรมและงานวิจัยให้ผู้ประกอบการ นักลงทุน ธุรกิจ startup ที่มองหานวัตกรรมใหม่ ๆ ไป<span class="whitespace-nowrap">ต่อยอด</span>ธุรกิจหรือผลิตภัณฑ์ของตัวเอง ได้ย้อนเวลากลับไปค้นหา เดินชม นวัตกรรมและผลงานวิจัยด้านการเกษตร วิทยาศาสตร์และเทคโนโลยี ของมหาวิทยาลัยเกษตรศาสตร์ ที่นำมาจัดแสดงในงานเกษตรแฟร์ทุก ๆ ปี ซึ่งแต่ละปี จะมีนวัตกรรมใหม่ ๆ ผลงานวิจัยใหม่ ๆ มาจัดแสดงแบบไม่ซ้ำเดิม เป็นการถ่ายทอดความรู้และเทคโนโลยี ให้แก่สังคมอย่างต่อเนื่องไม่สิ้นสุด ผู้สนใจสามารถติดต่อนักวิจัยหรือสถาบันเพื่อต่อยอดเพิ่มเติมได้จากรายละเอียดที่ติดไว้ให้ใน time machine</p>
            <h1 class="text-3xl font-bold my-6 ">
              <span className="text-3xl font-bold bg-gradient-to-r from-[#006C67] via-[#069A8E] to-[#B2BB1E] text-transparent bg-clip-text bg-300% animate-gradient uppercase">Virtual Kasetfair</span>
            </h1>
            <p class="text-[20px] my-5 text-gray-700">
              ผลงานนี้ เป็นผลงานของนิสิตมหาวิทยาลัยเกษตรศาสตร์ แสดงถึงความสามารถของนิสิตมหาลัยเกษตรศาสตร์ ที่มีทักษะและวิชาการที่เข้มแข็ง 
            </p>
          </div>
          <div class="flex col-span-1 relative min-h-64">
            <iframe src="https://kasetfair.ku.ac.th/2567/tour/zoneA/" frameborder="0" className="w-full rounded-lg"></iframe>
          </div>
        </div>
      </div>
    </section>
  );
}