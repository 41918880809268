export default function MainSection() {
  const year = ["2565", "2566", "2567"];
  const imageUrls = ["./TimeSlot/2565.jpg", "./TimeSlot/2566.jpg", "./TimeSlot/2567.jpg"];
  const urlLinks = ["https://kasetfair.ku.ac.th/2565/", "https://kasetfair.ku.ac.th/2566/", "https://kasetfair.ku.ac.th/2567/"];

  return (
    <div id="main" className="flex overflow-auto w-full px-8">
      {year.map((year, index) => (
        <div key={index} className="rounded-lg m-4 p-4 border-solid border-2 border-gray-100 space-y-4">
          <div>
            <img src={imageUrls[index]} alt={`เกษตรแฟร์ ${year}`} className="object-cover min-w-[300px]" />
          </div>
          <div className="text-center text-[#006C67] font-semibold">
            <span className="text-xl">เกษตรแฟร์ {year}</span>
          </div>
            <div className="flex justify-center px-4 py-2">
            <a href={urlLinks[index]} className="flex items-center font-semibold space-x-2 border border-slate-400 px-6 py-2 rounded-lg text-slate-400 hover:text-slate-700 hover:border-slate-700 hover:bg-[#005555] link-hover">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-shuffle my-auto icon transition-transform" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M0 3.5A.5.5 0 0 1 .5 3H1c2.202 0 3.827 1.24 4.874 2.418.49.552.865 1.102 1.126 1.532.26-.43.636-.98 1.126-1.532C9.173 4.24 10.798 3 13 3v1c-1.798 0-3.173 1.01-4.126 2.082A9.6 9.6 0 0 0 7.556 8a9.6 9.6 0 0 0 1.317 1.918C9.828 10.99 11.204 12 13 12v1c-2.202 0-3.827-1.24-4.874-2.418A10.6 10.6 0 0 1 7 9.05c-.26.43-.636.98-1.126 1.532C4.827 11.76 3.202 13 1 13H.5a.5.5 0 0 1 0-1H1c1.798 0 3.173-1.01 4.126-2.082A9.6 9.6 0 0 0 6.444 8a9.6 9.6 0 0 0-1.317-1.918C4.172 5.01 2.796 4 1 4H.5a.5.5 0 0 1-.5-.5"/>
                    <path d="M13 5.466V1.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192m0 9v-3.932a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192"/>
                </svg>
                <span className="transition-transform text">เข้าชม</span>
            </a>
            </div>

        </div>
      ))}
    </div>
  );
}



